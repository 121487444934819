<template>
  <div class="p-4 bg-gray-900 text-white min-h-screen main">
    <h1 class="text-3xl mb-4 logo"><img src="https://exburst.dev/ua/logoexburst_ua.png"></h1>
    <exburst-tool-list :tools="tools"></exburst-tool-list>
  </div>
</template>

<script>
import ExburstToolList from './ExburstToolList.vue';

export default {
  components: {
    ExburstToolList
  },
  data() {
    return {
      tools: [
        {
          id: 1,
          name: 'Union Arena Deckbuilder (Asia Ver.)',
          description: 'Union Arena Deckbuilder, Card list and Tournaments. (Asia Version)',
          image: 'https://exburst.dev/icon/ua/exburst_UA_JP.png',
          status: 'online',
          version: 'v2.2.X',
          links: [
            { name: 'Go to Tool', url: 'ua' }
          ],
          keywords: ['Union Arena', 'Deckbuilder', 'Asia', 'TCG']
        },
        {
          id: 5,
          name: 'Union Arena Deckbuilder (US Ver.)',
          description: 'Union Arena Deckbuilder, Card list and Tournaments. (NA Version)',
          image: 'https://exburst.dev/icon/uaen/exburst_UA_NA.png',
          status: 'online',
          version: 'v2.2.X',
          links: [
            { name: 'Go to Tool', url: 'ua/en' }
          ],
          keywords: ['Union Arena', 'Deckbuilder', 'US', 'TCG']
        },
        {
          id: 8,
          name: 'Pokemon TCG Pocket Deckbuilder',
          description: 'A powerful deckbuilding tool for Pokemon TCG Pocket.',
          image: 'https://exburst.dev/icon/ptcgp_logo.png',
          status: 'online',
          version: 'v2.0',
          links: [
            { name: 'Go to Tool', url: 'pocket' }
          ],
          keywords: ['Lab', 'Deckbuilder', 'CCG', 'TCG']
        },
        {
          id: 6,
          name: 'Use my deckbuilder for your own game!',
          description: 'Contact me to create your own deckbuilder for any game you want!',
          image: 'https://exburst.dev/icon/playing-tcg-online.webp',
          status: 'online',
          version: '',
          links: [
            { name: 'Contact Me!', url: 'mailto:support@exburst.dev?subject=I%20would%20like%20to%20create%20my%20own%20deckbuilder...' }
          ],
          keywords: ['Deckbuilder', 'Custom Tool', 'Contact']
        },
        {
          id: 7,
          name: 'Make me Develop your own web tool!',
          description: 'Interested in developing a custom web tool? Contact us for more details!',
          image: 'https://exburst.dev/icon/requesttool.png',
          status: 'online',
          version: '',
          links: [
            { name: 'Contact Me!', url: 'mailto:support@exburst.dev?subject=I%20would%20like%20to%20create%20my%20own%20tool...' }
          ],
          keywords: ['Web Tool', 'Custom Development', 'Contact']
        }
      ]
    };
  }
};
</script>

<style scoped>
.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto; /* Allows vertical scrolling */
  margin-bottom: 100px;
  height: 100%;
}

.logo {
  max-width: 100%;
  max-height: 200px;
}
</style>
